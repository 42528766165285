@media all and (min-width: 480px) {
    .Report {
        padding: 20px 0;
    }
    .Report {
        padding: 20px 0;
        margin: 0 auto;
        max-width: 380px;
    }
}
.panel-body{
    text-align: left;
}
.h2{
    padding: 100px;
}