@media all and (min-width: 480px) {
    .Analysis {
          padding: 20px 0;
      }
      .Analysis Form {
          padding: 20px 0;
          margin: 0 auto;
          max-width: 380px;
      }  
    }
      