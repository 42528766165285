@media all and (min-width: 480px) {
    .Confirm {
      padding: 60px 0;
    }
  
    .Confirm form {
      margin: 0 auto;
      max-width: 320px;
      text-align: left;
    }
  }
  
  .Confirm form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
  