.Generateqr .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Generateqr .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Generateqr .lander p {
    color: #999;
  }
  
  .Generateqr .users h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Generateqr .users p {
    color: #666;
  }
  