@media all and (min-width: 480px) {

  .UserGroup {
    padding: 60px 0;
  }

  .UserGroup Form {
      padding-bottom: 15px;
      margin: 0 auto;
      max-width: 380px;
    }
}