@media all and (min-width: 480px) {

  .NewUserGroup {
    padding: 60px 0;
  }

  .NewUserGroup Form {
      padding-bottom: 15px;
      margin: 0 auto;
      max-width: 380px;
    }
}
  