@media all and (min-width: 480px) {

  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
      text-align: left;
    }
  }
  
  .Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
  